import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, message, Table, Typography, Space, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../pages/css/Home.css';
import 'firebase/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { refs } from '../firebase/firebase';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

const { Title } = Typography;

const Meters = ({ toggleDrawer }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [metersData, setMetersData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    fetchData();
  }, []); // Fetch data on component mount

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const querySnapshot = await refs.meters.orderBy('createdAt', 'desc').limit(300).get();

      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      }));

      setMetersData(newArray);
    } catch (error) {
      console.error('Error fetching meters:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const showModal = (item) => {
    setIsModalVisible(true);
    setIsEditing(!!item);
    setEditItemId(item ? item.id : null);

    if (item) {
      // If editing, set the form values
      form.setFieldsValue({
        meterId: item.meterId,
        carNumber: item.carNumber,
        carOwner: item.carOwner,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishMeter = async (values) => {
    try {
      const { meterId, carNumber, carOwner, disabled } = values;
      const now = new Date(); // Get the current date and time
  
      // Check if meterId already exists
      const existingMeter = await refs.meters.where('meterId', '==', meterId).get();
  
      if (!existingMeter.empty) {
        // If meterId already exists, show an alert and return
        message.error('Meter ID already exists. Please use a different ID.');
        return;
      }
  
      if (isEditing && editItemId) {
        // If editing, update the existing record
        await refs.meters.doc(editItemId).update({
          meterId,
          carNumber,
          carOwner,
          disabled: disabled || true, // Set the disabled status to true by default
          createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Add the createdAt field
        });
        message.success('Meter updated successfully!');
      } else {
        // If not editing, add a new record
        const docRef = await refs.meters.add({
          meterId,
          carNumber,
          carOwner,
          disabled: disabled || true, // Set the disabled status to true by default
          createdAt: now, // Add the createdAt field
        });

        await refs.users.add({
          carNumber,
          username: carOwner,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        message.success('Meter added successfully!');
      }
  
      form.resetFields();
      fetchData();
      setIsEditing(false);
      setEditItemId(null);
    } catch (error) {
      console.error('Error adding/updating meter:', error.message);
      message.error('Failed to add/update meter. Please try again.');
    }
  };

  const handleDisableMeter = async (record) => {
    try {
      await refs.meters.doc(record.id).update({
        disabled: !record.disabled,
      });
      message.success(`Meter ${record.disabled ? 'enabled' : 'disabled'} successfully!`);
      fetchData();
    } catch (error) {
      console.error('Error updating meter disabled status:', error.message);
      message.error('Failed to update meter disabled status. Please try again.');
    }
  };



  const handleDelete = async (itemId) => {
    try {
      await refs.meters.doc(itemId).delete();
      message.success('Meter deleted successfully!');
      fetchData();
    } catch (error) {
      console.error('Error deleting meter:', error.message);
      message.error('Failed to delete meter. Please try again.');
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div>{text ? text.toString() : ''}</div>
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const downloadExcel = () => {
    const dataToExport = metersData.map(item => ({
      meterId: item.meterId,
      carNumber: item.carNumber,
      carOwner: item.carOwner,
      createdAt: item.createdAt ? moment(item.createdAt.toDate()).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
      activationDate: item.activationDate ? moment(item.activationDate.toDate()).format('YYYY-MM-DD HH:mm:ss') : 'Not Activated'
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Meters");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'meters_data.xlsx');
  };

  const handleActivateMeter = async (record) => {
    try {
      const activationDate = new Date();
      await refs.meters.doc(record.id).update({
        activationDate: activationDate
      });
      message.success('Meter activated successfully!');
      fetchData();
    } catch (error) {
      console.error('Error activating meter:', error.message);
      message.error('Failed to activate meter. Please try again.');
    }
  };

  const [form] = Form.useForm();



  const columns = [
    {
      title: 'Meter Id',
      dataIndex: 'meterId',
      key: 'meterId',
      ...getColumnSearchProps('meterId'),
    },
    {
      title: 'Car Number',
      dataIndex: 'carNumber',
      key: 'carNumber',
      ...getColumnSearchProps('carNumber'),
    },
    {
      title: 'Car Owner',
      dataIndex: 'carOwner',
      key: 'carOwner',
      ...getColumnSearchProps('carOwner'),
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: (_, record) => (
    //     <span>
    //       <Button onClick={() => showModal(record)}>Edit</Button>
    //       <Button type="danger" onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>
    //         Delete
    //       </Button>
    //     </span>
    //   ),
    // },
    {
      title: 'Activation Date',
      dataIndex: 'activationDate',
      key: 'activationDate',
      render: (text, record) => record.activationDate ? moment(record.activationDate.toDate()).format('YYYY-MM-DD HH:mm:ss') : 'Not Activated',
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (disabled, record) => (
        <Switch
          checked={!disabled}
          onChange={() => handleDisableMeter(record)}
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button onClick={() => showModal(record)}>Edit</Button>
          <Button type="danger" onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>
            Delete
          </Button>
          {!record.activationDate && (
            <Button type="primary" onClick={() => handleActivateMeter(record)} style={{ marginLeft: 8 }}>
              Activate Meter
            </Button>
          )}
        </span>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="header">
        <Title style={{ color: '#fff' }} level={2}>
          Meters
        </Title>
      </div>
      <Button style={{ marginTop: 50, marginBottom: 16 }} type="primary" onClick={showModal}>
        Add New
      </Button>
      <Button style={{ marginBottom: 16 }} onClick={downloadExcel}>
        Download Excel
      </Button>
      <Modal
        title={isEditing ? "Edit Meter" : "Add New Meter"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {isEditing ? "Update" : "Add"} Record
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onFinishMeter}>
          <Form.Item label="Meter Id" name="meterId" rules={[{ required: true, message: 'Please enter Meter Id!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Car Number" name="carNumber" rules={[{ required: true, message: 'Please enter Car Number!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Car Owner" name="carOwner" rules={[{ required: true, message: 'Please enter Car Owner!' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Table dataSource={metersData} columns={columns} loading={isLoading} />
    </div>
  );
};

export default Meters;
