
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { isDev } from '../utils';

export const db = firebase.firestore;
export const auth = firebase.auth;
export const storage = firebase.storage;
export const functions = firebase.functions;


const firebaseProdConfig = {
    apiKey: "AIzaSyDB8xqPWpTdphXSrhzcrZ6A4LJpTHe9HJg",
    authDomain: "driver-app-7505a.firebaseapp.com",
    projectId: "driver-app-7505a",
    storageBucket: "driver-app-7505a.appspot.com",
    messagingSenderId: "853727058025",
    appId: "1:853727058025:web:72f989d8bee91fb4f16af8",
    measurementId: "G-H05CQJGSL7"
};
const firebaseDevConfig = {
    apiKey: "AIzaSyBtz-b0Xg9Hre7ppMkNa8XRIFEDvyoTKFg",
    authDomain: "harbourcabs-dev.firebaseapp.com",
    projectId: "harbourcabs-dev",
    storageBucket: "harbourcabs-dev.appspot.com",
    messagingSenderId: "576075458433",
    appId: "1:576075458433:web:ab8719edf51ba46b63ea80",
    measurementId: "G-EMPS1NHHZG"
  };

  const projectConfig = isDev() ? firebaseDevConfig : firebaseProdConfig;
//initialize firebase with above config
firebase.initializeApp(projectConfig);

/****************** firestore refs ******************/

const users = db().collection('users');
const usersData = db().collection('usersData');

const rides = db().collection('rides')
const ridesData = db().collection('ridesData')
const meters = db().collection('meters')

const tolls = db().collection('tolls')
const tollsAuto = db().collection('tollsAuto')

const packages = db().collection('package')
const tariffpackages = db().collection('tariffpackages')

const tariffData = db().collection('tariffData')

const registrations = db().collection('registrations')

const vehicleInfo = db().collection('vehicleInfo')



/*****************************************/

export const refs = {
    users,
    rides,
    tolls,
    tollsAuto,
    meters,
    packages,
    tariffpackages,
    tariffData,
    usersData,
    ridesData,
    registrations,
    vehicleInfo

};



// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBh0WYH26yNFjI2wnP6G4QSELOXqdAK68M",
//   authDomain: "drivermanagment-b1f9f.firebaseapp.com",
//   projectId: "drivermanagment-b1f9f",
//   storageBucket: "drivermanagment-b1f9f.appspot.com",
//   messagingSenderId: "9753863073",
//   appId: "1:9753863073:web:e4104fe474d773fde121e0",
//   measurementId: "G-TG863Q9MTB"
// };
 
// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// // Initialize Firebase Authentication and get a reference to the service
// export const auth = getAuth(app);
// export default app;